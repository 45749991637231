import { Container, Row, Col, Image, Nav, Modal } from 'react-bootstrap'
import Masonry from 'react-masonry-css';
import { useState } from "react"
import ImageGallery from "react-image-gallery";

const Banner = () => {

  const [images] = useState(
    Array.from({ length: 41 }, (_, i) => ({
      original: `${i + 1}.jpg`,
      thumbnail: `${i + 1}.jpg`,
    }))
  );
  const [showModal, setShowModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState('');

  const handleImageClick = (image) => {
    const link = document.createElement('a');
    link.href = image;
    link.download = image.split('/').pop(); // Use the image filename as the download name
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleClose = () => setShowModal(false);

    const breakpointColumnsObj = {
  default: 3,
  1100: 3,
  900: 2,
  500: 1,
};
  return (
    <>
      <div className='banner memes'>
        <Container>
            <Row>
            <h3>Library of Memes</h3>
            <ImageGallery items={images} showPlayButton={false} showFullscreenButton={false} showThumbnails={true}/>
            {/* <Masonry
        breakpointCols={breakpointColumnsObj}
        className="my-masonry-grid"
        columnClassName="my-masonry-grid_column"
      >
        {images.map((image, index) => (
          <div key={index} onClick={() => handleImageClick(image)}>
            <Image src={image} thumbnail />
          </div>
        ))}
      </Masonry> */}
      {/* <Modal show={showModal} onHide={handleClose}>
        <Modal.Body closeButton>
          <div className="close" onClick={handleClose}>X</div>
          <Image src={selectedImage} fluid />
        </Modal.Body>
      </Modal> */}
            </Row>
        </Container>
      </div>
    </>
  )
}

export default Banner
