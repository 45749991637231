// router
import { BrowserRouter as Router} from 'react-router-dom'

// components
import Footer from '../components/Footer'
import Banner from '../components/Banner'
import MC from '../components/MC'
import Hero from '../components/Hero'
import Memes from '../components/Memes'
import Header from '../components/Header'

const Layout = ({ children }) => {
  return (
    <Router>
      <Header />
      <main>{children}</main>
      <MC />
      <Banner />
      <Hero />
      <Memes />
      <Footer />
    </Router>
  )
}

export default Layout
