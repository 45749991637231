import { Container, Row, Col, Image, Nav } from 'react-bootstrap'
import { LinkContainer } from 'react-router-bootstrap';

const Header = ({ head, description }) => {
  function myFunction() {
    var x = document.getElementById("myLinks");
    if (x.style.display === "block") {
      x.style.display = "none";
    } else {
      x.style.display = "block";
    }
  }

  return (
    <>
      <header className='d-flex flex-wrap align-items-center justify-content-center justify-content-md-between topnav'>
        <LinkContainer to='/'>
          <Nav.Link className='d-flex align-items-center col-md-3 mb-2 mb-md-0 text-decoration-none heading'>
            <img class="logo" alt="Make The VP Great Again"src="logo.jpeg"/>
          </Nav.Link>
        </LinkContainer>
  <a href="javascript:void(0);" className="hamburger icon mobile" onClick={myFunction}>
    <img src="menu.png"/>
  </a>
        <Nav>
        <div className="clear">
        <div id="myLinks">
          <Col xs={2} sm={1} className=""><a href="https://x.com/VenceSol" target="_blank" rel="noreferrer"><img alt="twitter" src="twit.png"></img></a></Col>
            <Col xs={2} sm={1} className=""><a href="https://t.me/+YlSzRscwx1Y2OWNh" target="_blank" rel="noreferrer"><img alt="telegram" src="tele.png"></img></a></Col>
            <Col xs={2} sm={1} className=""><a href="https://dexscreener.com/solana/447mf9vea8f9w7kmkz8t4nsyndpdk7i4fm8tujeutvtp" target="_blank" rel="noreferrer"><img alt="dexscreener" src="screen.png"></img></a></Col>
            <Col xs={2} sm={1} className=""><a href="https://www.dextools.io/app/en/solana/pair-explorer/447mf9VeA8F9W7kmKz8t4NsyNDpDK7i4FM8tujeuTVtP?t=1720501697261" target="_blank" rel="noreferrer"><img alt="dextools" src="tools.png"></img></a></Col>
          
  </div>
  </div>
          <div className='text-end'>
          <Col xs={4} sm={1} className="social-icons"><a href="https://x.com/VenceSol" target="_blank" rel="noreferrer"><img alt="twitter" src="twit.png"></img></a></Col>
            <Col xs={4} sm={1} className="social-icons"><a href="https://t.me/+YlSzRscwx1Y2OWNh" target="_blank" rel="noreferrer"><img alt="telegram" src="tele.png"></img></a></Col>
            <Col xs={4} sm={1} className="social-icons"><a href="https://dexscreener.com/solana/447mf9vea8f9w7kmkz8t4nsyndpdk7i4fm8tujeutvtp" target="_blank" rel="noreferrer"><img alt="dexscreener" src="screen.png"></img></a></Col>
            <Col xs={4} sm={1} className="social-icons"><a href="https://www.dextools.io/app/en/solana/pair-explorer/447mf9VeA8F9W7kmKz8t4NsyNDpDK7i4FM8tujeuTVtP?t=1720501697261" target="_blank" rel="noreferrer"><img alt="dextools" src="tools.png"></img></a></Col>
          
          </div>
        </Nav>
      </header>
    </>
  )
}

export default Header
