import { Container, Row, Col, Image } from 'react-bootstrap'

const Footer = () => {
  return (
    <>
      <footer className='text-left'>
        <Container>
            <Row>
            <Col xs={3} sm={3} className="mobile social-icons"><a href="https://x.com/VenceSol" target="_blank" rel="noreferrer"><img alt="twitter" src="twit.png"></img></a></Col>
            <Col xs={3} sm={3} className="mobile social-icons"><a href="https://t.me/+YlSzRscwx1Y2OWNh" target="_blank" rel="noreferrer"><img alt="telegram" src="tele.png"></img></a></Col>
            <Col xs={3} sm={3} className="mobile social-icons"><a href="https://dexscreener.com/solana/447mf9vea8f9w7kmkz8t4nsyndpdk7i4fm8tujeutvtp" target="_blank" rel="noreferrer"><img alt="dexscreener" src="screen.png"></img></a></Col>
            <Col xs={3} sm={3} className="mobile social-icons"><a href="https://www.dextools.io/app/en/solana/pair-explorer/447mf9VeA8F9W7kmKz8t4NsyNDpDK7i4FM8tujeuTVtP?t=1720501697261" target="_blank" rel="noreferrer"><img alt="dextools" src="tools.png"></img></a></Col>
              
              <p class="contract-text center">&copy; 2024 by JD $VENCE. All rights reserved. <br/><br/>$VENCE has no association with JD Vance. This token is simply a meme coin with no intrinsic value or expectation of financial return.</p>
              <p class="contract-text right"></p>
            </Row>
        </Container>
      </footer>
    </>
  )
}

export default Footer
