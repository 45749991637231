import { Container, Row, Col, Image } from 'react-bootstrap'
import React, { useState, useEffect } from 'react';

const MC = () => {
  // get data.usd_market_cap from https://frontend-api.pump.fun/coins/C1aUK5VpDoEKqsvDpc7gQf1HVdgbrw3kwXE7KEbJpump
  // if it's in millions then round it to two decimal places, ie. 1.56 and put $ in front and M behind, ie. $1.56M then put in ${marketcap}
  // if no data then just put '-'

  // get data.liquidity from https://app.meteora.ag/clmm-api/pair/AmKSPTnD9WgZKzRkb9zV8AkVtWy44HDzQsXb6TS8PAwc
  // round it to thousands, ie $76k then put in ${TVL}
    // if no data then just put '-'

    const [marketCap, setMarketCap] = useState('-');
    const [TVL, setTVL] = useState('-');

    useEffect(() => {
      const fetchMarketCap = async () => {
        try {
          const response = await fetch('https://api.dexscreener.com/latest/dex/pairs/solana/447mf9vea8f9w7kmkz8t4nsyndpdk7i4fm8tujeutvtp');
          const data = await response.json();
          let pair = data.pairs[0]
          if (pair.fdv) {
            let cap = pair.fdv / 1e6;
            cap = cap.toFixed(2);
            setMarketCap(`$ ${cap}M`);
          }
        } catch (error) {
          console.error('Error fetching market cap:', error);
        }
      };
  
      const fetchTVL = async () => {
        try {
          const response = await fetch('https://app.meteora.ag/clmm-api/pair/AmKSPTnD9WgZKzRkb9zV8AkVtWy44HDzQsXb6TS8PAwc');
          const data = await response.json();
          if (data.liquidity) {
            let liquidity = data.liquidity / 1e3;
            liquidity = liquidity.toFixed(0);
            setTVL(`$ ${liquidity}K`);
          }
        } catch (error) {
          console.error('Error fetching TVL:', error);
        }
      };
  
      fetchMarketCap();
      fetchTVL();
    }, []);


  return (
    <>
      <div className='banner mc'>
        <Container>
            <Row>
              <p>Support the tremp/vence ecosystem and supply LP on Meteora DLMM</p>
            <Col xs={12} sm={6} className="LP"><img src="lp.png" className="mc-icon" alt="Metorea"/><a href="https://app.meteora.ag/dlmm/AmKSPTnD9WgZKzRkb9zV8AkVtWy44HDzQsXb6TS8PAwc" target="_blank" className="data">Live TVL:</a><br/>{TVL}</Col>
            <Col xs={12} sm={6} className="cap"><img src="vencemc.png" className="mc-icon" alt="Metorea"/><a href="https://dexscreener.com/solana/447mf9vea8f9w7kmkz8t4nsyndpdk7i4fm8tujeutvtp" target='_blank' className="data">Live $VENCE MC:</a><br/>{marketCap}</Col>

            </Row>
        </Container>
      </div>
    </>
  )
}

export default MC
