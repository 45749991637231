import { Container, Row, Col, Image, Nav } from 'react-bootstrap'
import { LinkContainer } from 'react-router-bootstrap';

const Hero = () => {
  return (
    <>
            <div class="section-bg">
              <div class="overlay">
          <Container>
          <Row>
            {/* <h3>Get Involved</h3>
            <p>We are currently working with established communities such as 'People of  Walmart' and 'Florida Man Meme Corporation'. While these volunteers selflessly give up their time to save Web3, we call upon the public to do their part:</p>
            <ul>

              <li>Become an investor and support our movement</li>
              <li>Denounce fudders as people who support rug artists</li>
              <li>Make sure to like, retweet and share our tweets to the community</li>
              <li>Give it 20 minutes before dumping on the floor of your newly minted nft</li>
              <li>Don’t do your own research and DON’T DUMP.</li>

            </ul> */}

<h3>About JD Vence</h3>
            <p>JD grew up in Middletown, Ohio, once a thriving manufacturing town, now struggling like many American heartland communities. Amid family challenges, his grandmother Mamaw's tough love kept him grounded.</p>
<p>JD enlisted in the Marine Corps right out of High School. After two years in the Marines, JD enrolled at Ohio State and subsequently Yale Law. Upon graduating he took a job as a law clerk, but moved to Silicon Valley to work in venture capital after one year.</p>
<p>Early in his career he also authored the bestselling memoir "Hillbilly Elegy," which was later turned into a Netflix movie directed by Ron Howard. He is the first Marine Veteran to be nominated for VP. And, he is the first VP nominee to hold Bitcoin!
</p>
            </Row>

    </Container>
    </div>
    </div>
    </>
  )
}

export default Hero
