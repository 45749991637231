import { Container, Row, Col, Image } from 'react-bootstrap'

const Banner = () => {
  return (
    <>
      <div className='banner'>
        <Container>
            <Row>
            <h3>Help Make The VP Great Again!</h3>
            <Col xs={{ span: 12, order: 2 }} md={{ span: 8, order: 1 }}>
            <p><strong>How to buy $VENCE on Solana</strong></p>
            <ol>
            <li>Buy Solana on an exchange (like Coinbase or Binance) or in a wallet (like Phantom)</li>
            <li>Create a <a href="https://phantom.app/" target='_blank'>Phantom wallet</a></li>
            <li>Send/withdraw Solana to your Phantom wallet address</li>
            <li>Connect your Phantom wallet to <a href="https://jup.ag/swap/SOL-C1aUK5VpDoEKqsvDpc7gQf1HVdgbrw3kwXE7KEbJpump" target='_blank'>Jup</a> and trade Solana for $VENCE</li>
            <li>Ride with JD, tremp, and this community of chads to Valhalla! </li>
</ol>
</Col>            
<Col xs={{ span: 12, order: 1 }} md={{ span: 4, order: 2 }}>
<img className="buyimg" src="buy.jpg" alt="How to buy $VENCE"/>
</Col>
            </Row>
        </Container>
      </div>
    </>
  )
}

export default Banner
